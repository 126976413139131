.ant-tabs-tab {
    background-color: #7144D5 !important;
    /* border-width: 0px !important; */
    border-top-width: 0px !important;
    border-right-width: 0px !important;
    border-left-width: 0px !important;
    color: white !important;
    max-width: 180px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.ant-tabs-tab-unclosable {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.ant-tabs-tab-active {
    background-color: white !important;
    color: black !important;
}

.anticon-plus {
    background-color: white !important;
    border: none !important;
    color: #7144D5 !important;
    margin-top: 10px !important;
    opacity: 0.75;
}

.ant-tabs-tab-prev {
    color: white !important;
}

.ant-tabs-tab-next {
    color: white !important;
}

.ant-tabs-bar {
    margin: 0 !important;
}

@media screen and (max-width: 880px) {
    .help_sidebar {
        visibility: hidden;
        display: none;
    }
}
